.title-color-red {
  color: #c92323 !important;
  font-size: 3rem !important;
}

.lineHeight2 {
  line-height: 2rem;
}

.red-logo {
  color: #c92323 !important;
}

@media (max-width: 767px) {
  .width100Smarphone {
    width: 100% !important;
  }
}

@media (min-width: 767px) {
  .width70Pc {
    width: 70% !important;
  }
}

.fade-in {
  animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0; /* Define el estado inicial de la animación con opacidad 0 */
  }
  to {
    opacity: 1; /* Define el estado final de la animación con opacidad 1 (totalmente visible) */
  }
}

.overflowHidden {
  overflow: hidden !important;
}

.title-card {
  color: #c6bca9 !important;
}

.bold-900 {
  font-weight: 900 !important;
}

.minHeightWidth {
  height: 500px !important;
}

.color-blunav {
  background-color: #0f1c41 !important;
}
.colortext-blunav {
  color: #0f1c41 !important;
}

.bold-600 {
  font-weight: 600 !important;
}
.line-height3 {
  line-height: 3rem;
}

.line-height2 {
  line-height: 2rem;
}

.animated-gradient {
  width: 100%;

  background: linear-gradient(
    45deg,
    #140636,
    #050e42,
    #0b2e63,
    #064269
  ) !important;
  background-size: 200% 200% !important;
  animation: gradientAnimation 2s ease infinite !important;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.bgBluOpacity05 {
  background-color: rgb(15, 28, 65, 0.5) !important;
}

.bg-bluScuro {
  background-color: rgb(15, 28, 65, 0.8) !important;
}

.w-iconCard {
  width: 8rem !important;
}

.button-redLogo {
  background-color: #d02629 !important;
  color: rgb(252, 252, 252) !important;

  border: transparent !important;
  cursor: pointer;
}

.button-redLogo:hover {
  background-color: #721517 !important;
  cursor: pointer;
}

.width100vw {
  width: 100vw !important;
}

.radius10 {
  border-radius: 10px !important;
}

.w-iconLottie {
  width: 3rem !important;
  cursor: pointer;
}

.min-w100 {
  min-width: 100vw !important;
}

.w-90 {
  width: 90%;
}

@media (max-width: 767px) {
  .fontTitle-size-sm {
    font-size: 1.8rem !important;
  }
}

.button-linkStyle {
  background-color: transparent !important;
  color: #3888ff !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  border: transparent !important;
}

.button-linkStyle:hover {
  color: #0a58ca !important;
}

.img-article-intro {
  min-width: 100%;
  max-width: 100%;
}

@media (max-width: 767px) {
  .mt-Mobile {
    margin-top: 2rem !important;
  }
}

.card-style {
  min-height: 24rem;
}

.VH80 {
  height: 80vh;
}

.avatarImg {
  width: 5rem !important;
  height: 5rem !important;
  border-radius: 50%;
}

.button-redLogoTrasparent {
  background-color: transparent !important;
  color: #d02629 !important;
  font-weight: bold !important;
  border: solid 1px #d02629 !important;
  cursor: pointer;
}

.button-redLogoTrasparent:hover {
  color: white !important;
  background-color: #721517 !important;
  cursor: pointer;
}

.banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 9999;
}

.imgSingleUserGestion {
  height: 10rem !important;
  width: 10rem !important;
  border-radius: 50% !important;
}
.heigthCardUser {
  height: 32rem !important;
}

.zindexAccordion {
  z-index: 9999 !important;
}

.zindexCardUser {
  z-index: 0 !important;
}

.decorationNone {
  text-decoration: none !important;
}

.buttonDeleteAccount {
  width: 2.5rem;
  height: 2.5rem;
}

@media only screen and (min-width: 768px) {
  .hover-card:hover {
    transform: scale(1.1);
    z-index: 200;
    cursor: pointer;
  }
}

.overAuto {
  overflow: auto !important;
}

.heightCardInvestHome {
  height: 30rem !important;
}

.pointer {
  cursor: pointer;
}

/* Stile per desktop e schermi medi (iPad e più grandi) */
.banner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f0f0f0;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 9999;
  max-width: 600px; /* Larghezza massima per schermi più grandi */
  width: 100%; /* Adatta la larghezza al contenitore */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Aggiungi un'ombra per un effetto migliore */
}

/* Stile per dispositivi mobili con larghezza massima di 576px (smartphone) */
@media (max-width: 576px) {
  .banner {
    position: fixed;
    top: 0;
    left: 0;
    transform: none; /* Rimuovi il centramento per mobile */
    width: 100vw; /* Larghezza completa del viewport */
    height: auto; /* Altezza automatica basata sul contenuto */
    border-radius: 0; /* Rimuovi gli angoli arrotondati su mobile */
    padding: 1rem;
    box-sizing: border-box; /* Include padding nella larghezza totale */
    background-color: #f0f0f0; /* Mantieni il colore di sfondo */
    z-index: 9999; /* Mantieni il livello sopra gli altri elementi */
  }

  .banner img {
    max-width: 100%;
    height: auto; /* L'immagine si ridimensiona correttamente */
  }
}

.colorStarIcon {
  color: #fdd663 !important;
}

.dimensionImageUpdate {
  width: 100%;
  height: auto;
}

@media (min-width: 992px) {
  .dimensionImageUpdate {
    width: 70%;
  }
}

.custom-card-img {
  width: 100%; /* Rende l'immagine larga quanto il contenitore */
  height: 200px; /* Imposta un'altezza fissa */
  object-fit: cover; /* Ritaglia l'immagine mantenendo le proporzioni */
}

@keyframes zigzag {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px); /* La freccia si sposta di 30px a destra */
  }
  100% {
    transform: translateX(0); /* Torna alla posizione iniziale */
  }
}

.zigzag-animation {
  animation: zigzag 1s infinite ease-in-out;
}

.text-truncate-multiline {
  display: -webkit-box; /* Flessibile per il supporto di Webkit */
  -webkit-line-clamp: 5; /* Numero di righe da mostrare */
  -webkit-box-orient: vertical; /* Imposta l'orientamento verticale */
  overflow: hidden; /* Nasconde il testo che eccede il limite */
  text-overflow: ellipsis; /* Aggiunge i puntini di sospensione */
  line-height: 1.5; /* Altezza della linea, puoi modificare per regolare il layout */
  max-height: calc(
    1.5em * 5
  ); /* Calcola l'altezza massima basata sul numero di righe */
}

.review-box {
  width: 100%;
  margin-left: 1rem;
  height: 18rem; /* Imposta una larghezza fissa */
}

.preserve-line-breaks {
  white-space: pre-line;
}

@media (min-width: 992px) {
  /* lg e superiori */
  .width-70-LG {
    width: 70%;
  }
}

.dimension-image-gallery {
  width: 100% !important;
}

.position-relative {
  position: relative; /* Imposta la posizione relativa sul contenitore */
}

.delete-button-image-final {
  position: absolute; /* Imposta la posizione assoluta sul pulsante */
  bottom: 10px; /* Distanza dal basso */
  right: 10px; /* Distanza da destra */
  z-index: 10; /* Assicurati che il pulsante sia sopra l'immagine */
}

.overflow-x-scroll {
  overflow-x: auto !important;
}

.custom-row-width {
  max-width: fit-content !important;
}

.color-title-chiaro {
  color: #c6bca9 !important;
}

.padding05 {
  padding: 0.5 !important;
}

.vh80 {
  height: 80vh !important;
}

/* MEDIA QUERY PER NEWSLETTER */

.mediaQuery-w70 {
  width: 100%; /* Default per schermi piccoli */

  @media (min-width: 992px) {
    /* lg in Bootstrap (992px e oltre) */
    width: 70%;
  }
}

.text-center-mobile {
  text-align: left; /* Default per schermi più grandi */

  @media (max-width: 767px) {
    /* Mobile */
    text-align: center;
  }
}
