.line-height2 {
  line-height: 2.5rem;
}

.counter-style {
  width: 6rem;
}

@media (max-width: 767px) {
  .width-100Smarphone {
    width: 100% !important;
  }
}
@media (min-width: 767px) {
  .width-70Pc {
    width: 70% !important;
  }
}

.opacity-soft {
  opacity: 0.9;
}

.opacity1 {
  opacity: 1 !important;
}

.color-blunav {
  background-color: #0f1c41 !important;
}
.colortext-blunav {
  color: #0f1c41 !important;
}

.bold-600 {
  font-weight: 600 !important;
}
.line-height3 {
  line-height: 3rem;
}

.line-height2 {
  line-height: 2rem;
}
.button-redLogoMission {
  background-color: #d02629 !important;
  color: rgb(252, 252, 252) !important;

  border: transparent !important;
}

.button-redLogoMission:hover {
  background-color: #721517 !important;
  transform: scale(1.1);
}
